<template>
<button onclick="location.href='mailto:business@envex-ventures.com';">Contact Us</button>
<a href="https://tally.so/r/w7dk2L" target="_blank"><button id="button-two">Apply for Seed Funding</button></a>

    </template>
        
        <script>
        export default {
          name: 'ButtonComp',
          props: {
            msg: String
          }
        }
        </script>
        
        <style scoped>
      button {
        width: 250px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 30px;
        border: none;
        font-weight: bold;
        font-size: 16px;
        border-radius: 4px;
        background-color: #e3e3e3;
        color: #4d4d4d;
        font-family: Bai Jamjuree;
        margin-bottom: 20px;
      }
    
      #button-two {
        background-color: #4d4d4d;
        color:#e3e3e3;
       
      }
      a {
        text-decoration: none;
    }
     
        </style>