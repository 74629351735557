<template>
  <div class="home" style="overflow: hidden;">
    <LogoComp/>
    <ButtonComp/>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoComp from '@/components/LogoComp.vue'
import ButtonComp from '@/components/ButtonComp.vue'

export default {
  name: 'HomeView',
  components: {
    LogoComp, ButtonComp
  }
}
</script>

<style scoped>
/* Add any scoped styles if necessary */
</style>

