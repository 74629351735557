<template>
  <div id="app">
    <router-view />
    <footer class="footer">
      <nav class="footer-nav">
        <ul>
          <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        </ul>
        <p class="center-text">Envex International Inc.</p>
      </nav>
    </footer>
  </div>
</template>

<style>
body, html {
  background-color: #151515;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure app takes full height */
  margin-top: 10px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%; /* Ensure footer takes full width */
  box-sizing: border-box; /* Include padding in width calculation */
  background-color: #151515; /* Match the background color */
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-weight: lighter;
  font-size: 14px;
  font-style: italic;
  color: aliceblue;
  padding: 10px;
  box-sizing: border-box;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%; /* Ensure nav takes full width */
}

.footer-nav ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 0.5em;
  padding: 0;
  margin: 0;
  width: 100%; /* Ensure ul takes full width */
  box-sizing: border-box;
}

.footer-nav li {
  margin: 0;
  width: 100%; /* Ensure li takes full width */
  text-align: center; /* Center text within li */
}

.center-text {
  margin-top: 10px;
  font-size: 12px;
}

.router-link {
  text-decoration: none;
  color: inherit;
  display: block; /* Make link take full width */
  width: 100%; /* Ensure link takes full width */
  box-sizing: border-box;
}

nav {
  padding: 10px;
}

nav a {
  font-weight: bold;
  color: aliceblue;
}

@media (min-width: 600px) {
  .footer-nav ul {
    flex-direction: row;
    gap: 1.5em;
    width: auto; /* Revert to auto width for larger screens */
  }

  .footer-nav li {
    width: auto; /* Revert to auto width for larger screens */
  }

  .router-link {
    width: auto; /* Revert to auto width for larger screens */
  }
}
</style>


