<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet">
  <img alt="Logo-Picture" src="../assets/logo.png">
  <h1>„We invest, scale and acquire <br> companies with great potential.” <br> <span id="thin">- Youssef F. Khammas CEO & Founder</span></h1>
</template>

<script>
export default {
  name: 'LogoComp',
  props: {
    msg: String
  }
}
</script>

<style scoped>
img {
  width: 80%; /* Use a percentage to make it responsive */
  max-width: 500px; /* Limit the maximum width */
  display: block;
  margin: 100px auto 30px auto; /* Adjusted for better mobile spacing */
}

h1 {
  text-align: center;
  color: aliceblue;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 4vw; /* Use viewport width for responsive font size */
  margin-bottom: 35px;
}

#thin {
  font-weight: lighter;
}

@media (min-width: 768px) {
  img {
    width: 60%; /* Adjust width for tablets and larger screens */
    margin-top: 150px; /* Adjust top margin for larger screens */
  }

  h1 {
    font-size: 3vw; /* Adjust font size for larger screens */
  }
}

@media (min-width: 1024px) {
  img {
    width: 40%; /* Further adjust width for larger screens */
    margin-top: 200px; /* Further adjust top margin for larger screens */
  }

  h1 {
    font-size: 1.2vw; /* Further adjust font size for larger screens */
  }
}
</style>
